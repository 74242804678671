<template>
  <div>
    <ml-container>
      <ml-grid>
        <div class="tw-mt-12 tw-text-center tw-text-black tw-col-start-2 tw-col-span-6">
          <page-title>Contact us</page-title>
          <p class="tw-mb-6 tw-leading-relaxed">
            We look forward to hearing from you! To best assist you, please contact us at one of the following
            addresses:
          </p>
          <p class="tw-mb-3 tw-leading-relaxed">
            General Inquiries:
            <ml-link href="mailto:info@mangolola.com">info@mangolola.com</ml-link>
            <br>
            Legal Questions:
            <ml-link href="mailto:legal@mangolola.com">legal@mangolola.com</ml-link>
            <br>
            Jobs Opportunities:
            <ml-link href="mailto:jobs@mangolola.com">jobs@mangolola.com</ml-link>
            <br>
            Marketing & Sales:
            <ml-link href="mailto:marketing@mangolola.com">marketing@mangolola.com</ml-link>
            <br>
            Customer Service:
            <ml-link href="mailto:support@mangolola.com">support@mangolola.com</ml-link>
            <br>
            Press Inquiries:
            <ml-link href="mailto:media@mangolola.com">media@mangolola.com</ml-link>
            <br>
            Account Management<br>
            Couples Accounts:
            <ml-link href="mailto:couples@mangolola.com">couples@mangolola.com</ml-link>
            <br>
            Vendor Accounts:
            <ml-link href="mailto:vendors@mangolola.com">vendors@mangolola.com</ml-link>
          </p>
        </div>
      </ml-grid>
    </ml-container>
  </div>
</template>

<script>
import i18n from '@/i18n'
import MlContainer from "@/components/layout/MlContainer";
import PageTitle from "@/components/layout/titles/PageTitle";
import MlGrid from "@/components/layout/MlGrid";
import MlLink from "@/components/layout/MlLink";

export default {
  components: {
    MlLink,
    MlGrid,
    PageTitle,
    MlContainer,
  },
  layout: 'basic',
  metaInfo() {
    return {
      title: i18n.t('contact-us'),
      meta: [
        {
          name: 'description',
          content: i18n.t('description.contact')
        }
      ]
    }
  },
}
</script>
